import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA_wHhUD6NbpIUFXeWHXsWBAm8kxNsPaQ8",
  authDomain: "my-gpt-4a85f.firebaseapp.com",
  projectId: "my-gpt-4a85f",
  storageBucket: "my-gpt-4a85f.appspot.com",
  messagingSenderId: "38342650864",
  appId: "1:38342650864:web:b61a945a7934b8cd1a4b35",
  measurementId: "G-SZBCRH2WZ1"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();

export default app;